/**
 * Сбор метрик Web Vitals
 */
require(['OK/pms!webVitalsLogConfig'], function (webVitalsLogConfig) {
    var config = webVitalsLogConfig.config;
    if (config.enabled) {
        require(['OK/WebVitalsLogger'], function(WebVitalsLogger) {
            WebVitalsLogger.logger.activate(config.consoleLogsEnabled, config.inpReportsCapacity, config.clsReportsCapacity);
        });
    }
});


/**
 * Доопределение UA на клиенте
 */
(function () {
    if (OK.util.isIEMetro()) {
        document.documentElement.classList.add('ie-metro');
    }
})();

/**
 * Клики по пустой области тулбара.
 */
(function(document) {
    var topPanel = document.getElementById('topPanel');
    if (topPanel) {
        topPanel.addEventListener('click', function (e) {
            var target = (e.target || e.srcElement),
                targetElementName = target.tagName.toLowerCase();
            if (OK.Layers.stack.length && targetElementName != 'input' && targetElementName != 'a') {
                OK.Layers.unregisterLast('topPanelClick');
            }
        });
    }
})(document);

OK.showOnlineUsers = {
    top: 0,
    doResize: function() {
        // noop, переопределяется в abstractHooks.js
    }
};

OK.hideFeed = function(feedId) {
    var element = document.getElementById(feedId);
    if (!element) {
        return;
    }

    OK.css.display(element, 0);
};

require(['OK/utils/throttle'], function(throttle) {
    window.addEventListener('resize', throttle(100, function () {
        OK.setContentWidth();
    }));
});

(function () {
    if (document.getElementById('sc') != null) {
        // Fake toolbar should have scrollBar width
        var scrollBarWidth = OK.scrollBar.width;

        var scrollStyleTag = document.createElement('style');
        var scrollStyles = '.fake-toolbar {width: ' + scrollBarWidth + 'px}' +
            '.scroll-right-indent { right: ' + scrollBarWidth + 'px !important; }' +
            '.scroll-right-padding { padding-right: ' + scrollBarWidth + 'px !important; }' +
            '.scroll-right-margin { margin-right: ' + scrollBarWidth + 'px !important; }';
        var scrollHead = document.getElementsByTagName('head')[0];

        if (!OK.util.isIEMetro()) {
            scrollStyles += 'html.oh #hook_PopLayer_popLayer, html.oh .topPanel, html.oh .modal, html.oh .scroll-modal-indent {border-right:' + scrollBarWidth + 'px transparent solid}';
        }

        scrollHead.appendChild(scrollStyleTag);
        if (scrollStyleTag.styleSheet) {
            scrollStyleTag.styleSheet.cssText = scrollStyles;
        } else {
            var textNode = document.createTextNode(scrollStyles);
            scrollStyleTag.appendChild(textNode);
        }
    }
})();

//onPostMessage Ping\Pong для определения из фреймов что они запущены в портальном контексте
window.addEventListener('message',function(e) {
    if (e.data == 'ok_ping') {
        e.source.postMessage('ok_pong', '*');
    }
},false);

require(['OK/StartupTimingLogger'], function(logger) {
    logger.activate();
});

require(['OK/loggingModules'], function (loggingModules) {
    loggingModules.activate();
});

// Логируем загрузку полифиллов
(function () {
    var polyfillScriptTagIds = [
        'polyfills-script', 'polyfills-modern-script'
    ];

    polyfillScriptTagIds.forEach(function(scriptTagId) {
        var tag = document.getElementById(scriptTagId);
        logPolyfillsLoaded(tag);
    });

    function logPolyfillsLoaded (script) {
        if (script && script.getAttribute('data-loaded') === 'true') {
            OK.logging.logger.success('polyfillsLoaded', script.id, 'Polyfills were loaded');
        }
    }
})();
